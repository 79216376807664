<template>
  <div class="layout-content">
    <router-view/>
      <van-tabbar route v-model="active"
                  active-color="#a92b2a" inactive-color="#e7dfdb" placeholder class="layout-tabbar">
        <van-tabbar-item to="/home">
          <i slot="icon" class="iconfont icon-home-h"></i>
          <span class="text">首页</span>
        </van-tabbar-item>
        <van-tabbar-item to="/more" v-show="templeInfoData.templeMorePray === 1">
          <i slot="icon" class="iconfont icon-bless"></i>
          <span class="text">更多祈福</span>
        </van-tabbar-item>
        <van-tabbar-item to="/buddha" v-show="templeInfoData.templeSupplyBuddha === 1">
          <i slot="icon" class="iconfont icon-donate"></i>
          <span class="text">佛像供养</span>
        </van-tabbar-item>
        <van-tabbar-item to="/about">
          <i slot="icon" class="iconfont icon-about"></i>
          <span class="text">简介</span>
        </van-tabbar-item>
        <van-tabbar-item to="/my">
          <i slot="icon" class="iconfont icon-me"></i>
          <span class="text">我的</span>
        </van-tabbar-item>
      </van-tabbar>
    <!--    -->
  </div>
</template>

<script>
// 这里可以导入其他文件 例如：组件、工具js、第三方插件js,json文件、图片文件等
// eg: import 《组件名称》 from '《文件路径》'
import { mapState } from 'vuex'

export default {
  name: 'LayoutIndex',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  props: {},
  // 这里用于存放数据
  data () {
    return {
      active: 0,
    }
  },
//  计算属性 类似于data概念
  computed: {
    ...mapState('temple',['templeInfoData'])
  },
//  监控data 中数据变化
  watch: {},
//  方法集合
  methods: {},
//  生命周期 -创建完成（可以访问当前this 实例）
  created () {
  },
//  生命周期 - 挂载完成（可以访问Dom元素）
  mounted () {
  },
//  生命周期 - 创建之前
  beforeCreate () {
  },
//  生命周期 - 挂载之前
  beforeMount () {
  },
//  生命周期 - 更新之前
  beforeUpdate () {
  },
//  生命周期 - 更新之后
  updated () {
  },
//  生命周期 - 销毁之前
  beforeDestroy () {
  },
//  生命周期 - 销毁完成
  destroyed () {
  },
// 如果页面有keep-alive 缓存功能，这个函数会触发
  activated () {
  }

}
</script>

<style scoped lang="less">
.layout-tabbar {
  :deep .van-tabbar--fixed{
    height: 60px;
  }
  i.iconfont {
    font-size: 30px;
  }

  span.text {
    font-size: 14px;
  }


}
</style>
